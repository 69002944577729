import React, { createContext, useState } from 'react';

export type SideBarInitialValue = {
  unfoldable: boolean;
  setUnfoldable: (prev: boolean) => void;
  sidebarShow: boolean;
  setSidebarShow: (load: boolean) => void;
};

type ChildrenProps = {
  children: JSX.Element;
};

export const SideBarContext = createContext<SideBarInitialValue | null>(null);

export const SideBarProvider: React.FC<ChildrenProps> = ({ children }) => {
  const [unfoldable, setUnfoldable] = useState<boolean>(false);
  const [sidebarShow, setSidebarShow] = useState<boolean>(true);

  return (
    <SideBarContext.Provider
      value={{ unfoldable, sidebarShow, setUnfoldable, setSidebarShow }}
    >
      {children}
    </SideBarContext.Provider>
  );
};
